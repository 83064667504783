import { useCallback } from 'react'

import { useRouter } from 'next/compat/router'

export const useTabsUrlParams = () => {
  const router = useRouter()
  const updateTabUrlParam = useCallback(
    (newTab: string) => {
      if (!router) return

      const query = { ...router.query, tab: newTab }
      router
        .push(
          {
            pathname: router.pathname,
            query,
          },
          undefined,
          { shallow: true }
        )
        .catch((error: unknown) => {
          console.error('Error updating tab URL param:', error)
        })
    },
    [router]
  )

  if (!router) return { tab: undefined, updateTabUrlParam: () => {} }
  const { tab } = router.query

  return {
    tab: typeof tab === 'string' ? tab : undefined,
    updateTabUrlParam,
  }
}
