import { styled } from 'styled-components'

import { Breakpoints, Typography } from '@b-stock/bstock-react/design-system'
import { designColors } from '@b-stock/bstock-react/theme'

export const TableContainer = styled.div`
  display: grid;
  grid-template-columns: 18rem 1fr;
  grid-gap: 1.5rem;
  @media ${Breakpoints.max.medium} {
    grid-template-columns: 1fr;
  }
`

export const Pair = styled.div``

export const Table = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto auto;
  grid-gap: 2rem;
  ${Pair} {
    &:nth-child(-n + 3) {
      grid-row: 1;
    }
    &:nth-child(n + 4):nth-child(-n + 6) {
      grid-row: 2;
    }
    &:nth-child(7) {
      grid-row: 3;
      grid-column: 1/-1;
    }
    &:nth-child(8) {
      grid-area: 4 / 1 / auto / -1;
    }
  }
`

export const DetailsValue = styled.div`
  position: relative;
  ${Typography.Body2}
`

export const DetailsTitle = styled.div`
  color: ${designColors.neutral.darkGray};
  ${Typography.Body3}
`

export const Title = styled.div`
  ${Typography.Title2}
`
