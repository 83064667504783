import { useMemo } from 'react'

import { useIntl } from 'react-intl'
import { styled } from 'styled-components'

import { type StoreFrontDto } from '@b-stock/listing-api-client'

import type { AuctionFiltersState } from '@components/AuctionSearchProvider'
import AuctionSearchProvider from '@components/AuctionSearchProvider'
import type { SearchState } from '@components/BaseSearchProvider/types'
import { AuctionLayout } from '@components/features/auction/AuctionsLayout'
import { UnloggedWarning } from '@components/features/auction/details/UnloggedWarning'
import { getSellerPageFilters } from '@components/SearchFilters'

export const UnloggedWarningWrap = styled.div`
  margin-bottom: 5rem;
`

type ListingsContentProps = {
  initialAuctionSearchState: SearchState<AuctionFiltersState>
  sellerName: string
  sellerId: string
  storefront: StoreFrontDto | null | undefined
  isLoggedIn: boolean
}

const ListingsContent = ({
  initialAuctionSearchState,
  sellerId,
  sellerName,
  storefront,
  isLoggedIn,
}: ListingsContentProps) => {
  const { formatMessage } = useIntl()

  const hasLimitedVisibility =
    storefront?.options?.listingsLimitedVisibility && !isLoggedIn

  const forceFilters = useMemo(() => ({ sellerId: [sellerId] }), [sellerId])

  return (
    <>
      {hasLimitedVisibility ? (
        <UnloggedWarningWrap>
          <UnloggedWarning
            reason={formatMessage(
              { id: 'AuctionList.unloggedReason' },
              { seller: storefront.name }
            )}
          />
        </UnloggedWarningWrap>
      ) : (
        <AuctionSearchProvider
          forceFilters={forceFilters}
          initialState={initialAuctionSearchState}
        >
          <AuctionLayout
            headerMessage="AuctionList.auctions"
            filters={getSellerPageFilters()}
            showSoldBy={false}
            showSearch
            searchText={formatMessage(
              { id: 'Seller.search.storefront' },
              { sellerName }
            )}
          />
        </AuctionSearchProvider>
      )}
    </>
  )
}

export default ListingsContent
