import { FormattedMessage } from '@b-stock/bstock-react'

import {
  DetailsValue,
  TableContainer,
} from '@components/features/auction/details/shared'

import CostcoAboutDetails from './cstAboutDetails'
import { InfoSection, Title } from './ui'

const AboutContent = () => {
  return (
    <TableContainer>
      <Title>
        <FormattedMessage id="Storefront.about.title" />
      </Title>
      <InfoSection>
        <DetailsValue>
          <CostcoAboutDetails />
        </DetailsValue>
      </InfoSection>
    </TableContainer>
  )
}

export default AboutContent
