import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from 'styled-components'

import {
  Breakpoints,
  Colors,
  Typography,
} from '@b-stock/bstock-react/design-system'

export const InfoSection = styled.div`
  position: relative;
  margin-bottom: 5rem;
`

export const Title = styled.h1`
  max-width: 80%;
  margin-top: 0;
  color: ${Colors.Semantic.Neutral.black};
  ${Typography.Title1}
`

export const Container = styled.div`
  ${Typography.Body2}
  margin-right: 1.5rem;
`

export const SubHeader = styled.h2`
  ${Typography.Title3}
`

export const TextBold = styled.span`
  ${Typography.Body2Semibold}
`
export const TextItalic = styled.span`
  font-style: italic;
`

export const StyledParagraph = styled.p`
  margin-bottom: 1rem;
`
export const StyledInnerParagraph = styled.p`
  margin: 0;
`

export const List = styled.ul`
  margin-bottom: 1rem;
  padding-left: 2rem;
`

export const SubList = styled.ul`
  margin-top: 0.5rem;
  padding-left: 2rem;
`

export const ListItem = styled.li`
  margin-bottom: 0.5rem;
`

export const StyledAccordionWrapper = styled.div`
  & > div {
    padding-left: 1rem;
    @media ${Breakpoints.max.medium} {
      margin-top: 2rem;
    }
  }
  & > div > div:first-child {
    padding: 1rem 0;
    /* border-bottom: 1px solid ${Colors.Semantic.Neutral.light_grey}; */
    font-weight: 700;
  }
  & > div > div:last-child {
    background-color: ${Colors.Semantic.Neutral.light_grey};
  }
`

export const AnswerContainer = styled.div`
  padding: 0.5rem 1rem;
`

export const StyledIcon = styled(FontAwesomeIcon)`
  color: ${Colors.Semantic.Info.highlight};
`

export const QuestionLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export const Section = styled.div`
  border-bottom: 1px solid ${Colors.Semantic.Neutral.light_grey};
  margin-bottom: 1rem;
`
