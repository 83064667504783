import {
  Container,
  SubHeader,
  TextBold,
  TextItalic,
  StyledParagraph,
  StyledInnerParagraph,
  List,
  SubList,
  ListItem,
} from './ui'

const CostcoAboutDetails = () => {
  return (
    <Container>
      <StyledParagraph>
        The Costco Wholesale Liquidation Auctions marketplace is a B2B auction
        marketplace intended for those businesses involved in wholesale and/or
        resale selling their merchandise in-store, online through a company
        website, and/or via third-party seller platforms.
      </StyledParagraph>
      <StyledParagraph>
        Costco Wholesale Liquidation Auctions is the official B2B liquidation
        marketplace of the nations top membership-only warehouse club, Costco
        Wholesale. Selling palletized apparel, automotive, major appliances,
        computers & tablets, consumer electronics, cookware, domestics, food &
        sundries, footwear, garden & patio, handbags, hardware, health & beauty,
        home furnishings, jewelry & watches, luggage, mixed lots, rugs,
        seasonal, toys, sporting goods and more.
      </StyledParagraph>
      <StyledParagraph>
        Apply to Costco Wholesale Liquidation Auctions in order to buy Costco
        liquidation inventory. Please take a moment to review the{' '}
        <TextBold>Marketplace Buyer Restrictions</TextBold> (
        <TextItalic>below</TextItalic>) to understand the buyer approval process
        for Costco Wholesale Liquidation Auctions marketplace. Only approved
        buyers can view Costco auctions.
      </StyledParagraph>
      <SubHeader>Product Categories</SubHeader>
      <List>
        <ListItem>
          Apparel
          <SubList>
            <ListItem>Men&apos;s Apparel</ListItem>
            <ListItem>Women&apos;s Apparel</ListItem>
            <ListItem>Girls&apos;s Apparel</ListItem>
            <ListItem>Boys&apos;s Apparel</ListItem>
          </SubList>
        </ListItem>
        <ListItem>Appliances</ListItem>
        <ListItem>Computers & Tablets</ListItem>
        <ListItem>Consumer Electronics</ListItem>
        <ListItem>Cookware</ListItem>
        <ListItem>Domestics</ListItem>
        <ListItem>Food & Sundries</ListItem>
        <ListItem>Footwear</ListItem>
        <ListItem>Garden / Patio</ListItem>
        <ListItem>Handbags</ListItem>
        <ListItem>Hardware</ListItem>
        <ListItem>Health & Beauty</ListItem>
        <ListItem>Home Furnishings</ListItem>
        <ListItem>Jewelry & Watches</ListItem>
        <ListItem>Luggage</ListItem>
        <ListItem>Mixed Lots</ListItem>
        <ListItem>Rugs</ListItem>
        <ListItem>Sporting Goods</ListItem>
        <ListItem>Toys</ListItem>
      </List>
      <SubHeader>Types of Liquidated Inventory</SubHeader>
      <StyledParagraph>
        The selection, quality, and quantity of available products liquidated
        through the Costco Wholesale Liquidation Auctions marketplace depend on
        a number of factors including: returns, shelf pulls and excess
        inventory.
      </StyledParagraph>
      <List>
        <ListItem>
          <TextBold>Member Returns</TextBold>
          <StyledInnerParagraph>
            Inventory that was purchased by customers and returned to the store
            for any of a number of reasons. The quality of inventory is
            dependent on the amount of use by the customer before returning and
            whether it was damaged or worn out in the process. Typically, Member
            Returns product shows little to no damage.
          </StyledInnerParagraph>
        </ListItem>
        <ListItem>
          <TextBold>Excess Inventory</TextBold>
          <StyledInnerParagraph>
            Inventory that did not sell and is in too small a quantity to place
            on typical Costco displays.
          </StyledInnerParagraph>
        </ListItem>
        <ListItem>
          <TextBold>Rare Shelf Pulls</TextBold>
          <StyledInnerParagraph>
            Inventory that is not in sellable condition directly to customers.
            The merchandise may or may not be functional with missing or broken
            parts.
          </StyledInnerParagraph>
        </ListItem>
      </List>
      <SubHeader>Inventory Conditions</SubHeader>
      <StyledParagraph>
        Costco Wholesale Liquidation Auctions inventory ranges in quality and
        inventory condition and is highlighted at the individual auction listing
        level. The range of merchandise conditions includes grades New, Like
        New, A/B, C/D, and Salvage.
      </StyledParagraph>
      <List>
        <ListItem>
          <TextBold>New</TextBold>
          <SubList>
            <ListItem>
              New merchandise that has never been used or worn.
            </ListItem>
          </SubList>
        </ListItem>
        <ListItem>
          <TextBold>Like New</TextBold>
          <SubList>
            <ListItem>
              Merchandise can be unsold merchandise or shelf pulls and have
              never been used by a member. Items may have minor wear and tear
              from handling and sorting. Boxes may have sorting labels applied
              to them for processing. Items are more likely to have original
              packaging and tags, though in some cases may also have return
              labels.
            </ListItem>
          </SubList>
        </ListItem>
        <ListItem>
          <TextBold>Grade A/B</TextBold>
          <SubList>
            <ListItem>
              Grade A/B can be member returns or unsold merchandise.
              <SubList>
                <ListItem>
                  <TextBold>Member Returns</TextBold>
                  <SubList>
                    <ListItem>
                      It&apos;s not uncommon for members to return unused
                      merchandise to Costco. Even if the member hasn&apos;t used
                      the item, if any portion of the packaging has been opened,
                      it can not go back on the sales floor. This unused
                      merchandise will be part of Grade A/B liquidation.
                    </ListItem>
                    <ListItem>
                      It is also not uncommon for members to return merchandise
                      to Costco after it has been tried. Reasons for return
                      include shipped in duplicate, buyer remorse, wrong size,
                      color, damage during shipping, wrong item, didn&apos;t
                      need, didn&apos;t work, missing part, or no reason given.
                      After receiving from a warehouse, the Costco Returns Depot
                      receiving from a warehouse, the Costco Returns Depot
                      conducts a visual screening but does not test items before
                      determining Grade. Expect that some percentage of items
                      may be damaged, torn, missing parts, missing manuals, or
                      otherwise incomplete/non-functional.
                    </ListItem>
                    <ListItem>
                      Although not a general requirement for a refund, most
                      members do return items in the original box.
                    </ListItem>
                    <ListItem>
                      Member Returns can include online purchases.
                    </ListItem>
                  </SubList>
                </ListItem>
                <ListItem>
                  <TextBold>Unsold Merchandise</TextBold>
                  <SubList>
                    <ListItem>
                      Unsold merchandise is merchandise that did not sell and is
                      in too small a quantity to place on typical Costco
                      displays.
                    </ListItem>
                  </SubList>
                </ListItem>
              </SubList>
            </ListItem>
          </SubList>
        </ListItem>
        <ListItem>
          <TextBold>Grade C/D</TextBold>
          <SubList>
            <ListItem>
              Grade C/D lots are member returns or warehouse displays that fall
              below the A/B Grade standard. Items may be heavily scratched
              and/or dented, have missing or broken parts.
            </ListItem>
          </SubList>
        </ListItem>
      </List>
      <SubHeader>Liquidation Lot Sizes</SubHeader>
      <StyledParagraph>
        Costco Wholesale Liquidation Auctions typically sell liquidated
        merchandise in a variety of lot sizes. Generally speaking, auctions are
        most often sold by the pallet. Typical lot sizes include one to ten
        pallet lots (also known as LTL or Less Than Truckload) and truckloads.
      </StyledParagraph>
      <StyledParagraph>Buying by the Pallet:</StyledParagraph>
      <List>
        <ListItem>
          Packaged inventory that can fit on an individual pallet, the smallest
          unit of retail storage efficiency. A pallet may also be packaged as
          oversized or double stacked.
        </ListItem>
        <ListItem>
          Typically a pallet is the classic wood construction but can also
          include plastic, metal or recycled material. A pallet may also rarely
          contain items that are packaged in boxes (such as for iPads or very
          high-end designer bags).
        </ListItem>
      </List>
      <StyledParagraph>Common Lot Sizes:</StyledParagraph>
      <List>
        <ListItem>
          LTL (Less Than Truckload)
          <SubList>
            <ListItem>
              A relatively small amount of liquidated freight which is defined
              as between 1-10 pallet spaces on a truck.
            </ListItem>
          </SubList>
        </ListItem>
        <ListItem>
          Full Truckload (FTL)
          <SubList>
            <ListItem>
              A truckload of liquidated inventory is typically defined as
              containing 11 or more pallet spaces of inventory.
            </ListItem>
            <ListItem>
              You can combine multiple LTL auctions to create a full truckload
              and save on shipping costs under certain circumstances.
            </ListItem>
          </SubList>
        </ListItem>
        <ListItem>
          Small Pack (Boxes):
          <SubList>
            <ListItem>
              A small amount of liquidated freight which is less than 1 pallet
              quantities, typically shipped in boxed and featuring free
              shipping.
            </ListItem>
          </SubList>
        </ListItem>
      </List>
      <SubHeader>
        Buy from Locations Near your Business to Save Time and Shipping Costs
      </SubHeader>

      <StyledParagraph>
        Individual auction listings highlight the location that the inventory in
        that listing will be shipped from. Auctions are closing daily, typically
        from the following locations:
      </StyledParagraph>

      <List>
        <ListItem>Atlanta, GA</ListItem>
        <ListItem>Carrollton, TX</ListItem>
        <ListItem>Dallas, TX</ListItem>
        <ListItem>Frederick, MD</ListItem>
        <ListItem>Fresno, CA</ListItem>
        <ListItem>Mira Loma, CA</ListItem>
        <ListItem>Monroe Township, NJ</ListItem>
        <ListItem>Salt Lake City, UT</ListItem>
        <ListItem>Sumner, WA</ListItem>
        <ListItem>Tolleson, AZ</ListItem>
        <ListItem>Tracy, CA</ListItem>
        <ListItem>Van Buren Township, MI</ListItem>
        <ListItem>West Palm Beach, FL</ListItem>
      </List>

      <SubHeader>Marketplace Buyer Requirements</SubHeader>
      <StyledParagraph>
        In order to view and bid on Costco auction lots, you must be an approved
        buyer. To successfully apply to the marketplace, you must provide A) a
        valid state-specific resale certificate for US buyers{' '}
        <TextBold>OR</TextBold> B) a Proof of Business for international buyers,
        when applying for marketplace approval. Please ensure that you provide a
        valid US address, as shipment must be accepted in the US.
      </StyledParagraph>
      <List>
        <ListItem>
          We recommend that you take a moment to review the list of
          state-specific resale forms by each state, as well as additional
          information on reseller certificates.
        </ListItem>
        <ListItem>
          Buyers must not resell inventory purchased from Costco Wholesale
          Liquidation Auctions within a 5 mile radius of a Costco retail store.
        </ListItem>
      </List>
      <SubHeader>Additional Resources</SubHeader>

      <StyledParagraph>
        If you have additional questions about Costco Wholesale Liquidation
        Auctions, please feel free to <TextBold>Contact Us</TextBold>! Be sure
        to check out the Costco Wholesale FAQ section for questions we
        frequently receive about Costco Wholesale Liquidation Auctions
        marketplace.
      </StyledParagraph>
      <StyledParagraph>
        To join the Costco Wholesale Liquidation Auctions marketplace, please
        apply here!
      </StyledParagraph>
    </Container>
  )
}

export default CostcoAboutDetails
