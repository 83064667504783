import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons'

import { Accordion, Link } from '@b-stock/bstock-react'

import {
  Container,
  SubHeader,
  TextBold,
  TextItalic,
  StyledParagraph,
  StyledAccordionWrapper,
  AnswerContainer,
  StyledIcon,
  QuestionLabel,
  Section,
} from './ui'

type Faq = {
  question: string
  answer: string | React.ReactNode
}

export type FaqSection = {
  title: string
  questions: Faq[]
}

// This will need to be revisited if we have additional sellers with different faqs. For now, this is sufficient for only Costco.
export const getFaqsForCostco = (sellerName: string): FaqSection[] =>
  [
    {
      title: 'Applying & Logging In',
      questions: [
        {
          question: `Eos deserunt possimus est dignissimos ${sellerName}?`,
          answer: (
            <>
              <TextItalic>Sample italic text right here.</TextItalic> Cum
              corporis quod vel esse cumque et enim sunt. Aut delectus quam ab
              facere{' '}
              <Link href="/mock-link">
                eveniet et internos adipisci ad aspernatur enim!
              </Link>{' '}
              Et quas deleniti in velit itaque ea numquam veritatis qui
              architecto <Link href="/mock-link2">dolorem nam magnam</Link>{' '}
              aperiam in culpa quidem ea rerum iure.
            </>
          ),
        },
        {
          question: 'Sample question goes here?',
          answer: (
            <>
              <StyledParagraph>
                <TextBold>Lorem ipsum dolor sit amet.</TextBold> Ex maiores
                voluptates et laudantium necessitatibus et unde quasi. Sed
                labore obcaecati et consequuntur sint et modi alias vel quia
                neque a illo velit et porro consequatur? Non fugit ullam qui
                facilis neque est architecto eligendi et molestiae vitae!
              </StyledParagraph>
              <StyledParagraph>
                Qui Quis repellat et aliquam omnis ut dolorem dolorem qui
                debitis repudiandae aut praesentium culpa aut nemo iure. Aut
                molestiae unde ea autem inventore aut aliquid debitis.
              </StyledParagraph>
              <StyledParagraph>
                Aut necessitatibus expedita qui tenetur quis est excepturi totam
                ut vitae tenetur. Et voluptatem odio qui repellendus omnis quo
                enim quia qui ipsum omnis quo maxime nostrum et libero obcaecati
                ad rerum voluptas. Ad voluptate dolorem et porro optio ea
                laborum accusamus qui corporis deserunt sed soluta itaque est
                iste voluptatem. At distinctio fugiat sit accusamus nihil nam
                iure blanditiis et expedita cumque?
              </StyledParagraph>
            </>
          ),
        },
      ],
    },
    {
      title: 'Bidding',
      questions: [
        {
          question: `Eos deserunt possimus est dignissimos ${sellerName}?`,
          answer: (
            <>
              <TextItalic>Sample italic text right here.</TextItalic> Cum
              corporis quod vel esse cumque et enim sunt. Aut delectus quam ab
              facere{' '}
              <Link href="/mock-link">
                eveniet et internos adipisci ad aspernatur enim!
              </Link>{' '}
              Et quas deleniti in velit itaque ea numquam veritatis qui
              architecto <Link href="/mock-link2">dolorem nam magnam</Link>{' '}
              aperiam in culpa quidem ea rerum iure.
            </>
          ),
        },
        {
          question: 'Sample question goes here?',
          answer: (
            <>
              <StyledParagraph>
                Qui Quis repellat et aliquam omnis ut dolorem dolorem qui
                debitis repudiandae aut praesentium culpa aut nemo iure. Aut
                molestiae unde ea autem inventore aut aliquid debitis.
              </StyledParagraph>
              <StyledParagraph>
                Qui Quis repellat et aliquam omnis ut dolorem dolorem qui
                debitis repudiandae aut praesentium culpa aut nemo iure. Aut
                molestiae unde ea autem inventore aut aliquid debitis.
              </StyledParagraph>
            </>
          ),
        },
      ],
    },
    {
      title: 'Payment & Shipping',
      questions: [
        {
          question: `Eos deserunt possimus est dignissimos ${sellerName}?`,
          answer: (
            <>
              <StyledParagraph>
                Qui Quis repellat et aliquam omnis ut dolorem dolorem qui
                debitis repudiandae aut praesentium culpa aut nemo iure. Aut
                molestiae unde ea autem inventore aut aliquid debitis.
              </StyledParagraph>
            </>
          ),
        },
        {
          question: 'Sample question goes here?',
          answer: 'Sample answer goes here.',
        },
      ],
    },
    {
      title: 'Other Questions & Troubleshooting',
      questions: [
        {
          question: `Eos deserunt possimus est dignissimos ${sellerName}?`,
          answer: (
            <>
              <TextBold>Lorem ipsum dolor sit amet.</TextBold> Ex maiores
              voluptates et laudantium necessitatibus et unde quasi. Sed labore
              obcaecati et consequuntur sint et modi alias vel quia neque a illo
              velit et porro consequatur? Non fugit ullam qui facilis neque est
              architecto eligendi et molestiae vitae!
            </>
          ),
        },
        {
          question: 'Sample question goes here?',
          answer: 'Sample answer goes here.',
        },
      ],
    },
  ] satisfies FaqSection[]

type FaqDetailsProps = {
  sections: FaqSection[]
}

const FaqDetails = ({ sections = [] }: FaqDetailsProps) => {
  return (
    <Container>
      {sections.map(({ title, questions }) => (
        <Section key={title}>
          <SubHeader>{title}</SubHeader>
          {questions.map(({ question, answer }) => (
            <StyledAccordionWrapper key={question}>
              <Accordion
                label={
                  <QuestionLabel>
                    <StyledIcon icon={faQuestionCircle} />
                    <span>{question}</span>
                  </QuestionLabel>
                }
              >
                <AnswerContainer>{answer}</AnswerContainer>
              </Accordion>
            </StyledAccordionWrapper>
          ))}
        </Section>
      ))}
    </Container>
  )
}

export default FaqDetails
