import { FormattedMessage } from '@b-stock/bstock-react'

import {
  DetailsValue,
  TableContainer,
} from '@components/features/auction/details/shared'

import FaqDetails, { type FaqSection } from './cstFaqDetails'
import { InfoSection, Title } from './ui'

type FaqContentProps = {
  sections: FaqSection[]
}

const FaqContent = ({ sections = [] }: FaqContentProps) => {
  return (
    <TableContainer>
      <Title>
        <FormattedMessage id="Storefront.faq.title" />
      </Title>
      <InfoSection>
        <DetailsValue>
          <FaqDetails sections={sections} />
        </DetailsValue>
      </InfoSection>
    </TableContainer>
  )
}

export default FaqContent
